.SubscribeBoxPopup {
	font-size: var(--textSm);
	padding-bottom: var(--spaceXs);

	blockquote {
		background: var(--colorSecondaryBg);
		padding: var(--spaceLg) var(--spaceXl);
		margin: var(--spaceMd) 0;
		position: relative;

		svg {
			position: absolute;
			color: var(--colorBrand);

			&:first-child {
				top: var(--spaceSm);
				left: var(--spaceSm);
			}
			&:last-child {
				bottom: var(--spaceSm);
				right: var(--spaceSm);
			}
		}
	}
}